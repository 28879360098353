import { Avatar, Dialog, DialogContent, DialogTitle, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { getHomeFeaturedForUser, getHomeFeaturedOnly, saveHomeFeatured } from "../../api/propertyApi";
import NoData from "../global-components/no-data";
import PropertyBox from "./property-box";
import LoadingData from "../global-components/loading-data";
import { Link } from "react-router-dom";
import LoadingButton from "../global-components/loading-btn";
import { useAuth } from "../../helpers/context";
import { resolvePersianAndArabicNumbers } from "../../helpers";
import { DEMO_VENDORS } from "../../constants";
import { useTranslation } from "react-i18next";

const pageSize = 50;
const publicUrl = process.env.REACT_APP_URL + "/";

function FeaturedPropertiesSection(props) {
  const [data, setData] = useState([]);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [Data2, setData2] = useState(props.data);
  const { token, vendor } = useAuth();
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const { t, i18n } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [loadingData, setLoadingData] = useState(true);

  const [selectionModel, setSelectionModel] = useState([]);
  useEffect(() => {
    if (props.isEdit) {
      getHomeFeaturedForUser({
        userId: token,
        page: page,
        status: "1",
        pageSize: pageSize,
        isAssigned: props.accountType === "2" ? "0" : "na",
        language: i18n.language === 'ar' ? '0' : '1',
      })
        .then((property) => {
          if (property.resCode === 0) {
            setData(property.response.propertyArray);
            setTotalCount(property.response.totalCount);
            let temp = [], temp2 = [];
            for (let item of property.response.propertyArray) {
              if (item.isHomeFeatured) {
                temp.push(item.propertyId);
                temp2.push(item);
              }
            }
            setSelectedProperties(temp2);
            setSelectionModel(temp);
            setLoadingData(false);
          } else {
            toast.error(property.resStr);
            setLoadingData(false);
          }
        });
    } else {
      if (vendor)
        getHomeFeaturedOnly({ vendorName: vendor, page: 1 }).then((property) => {
          if (property.resCode === 0) {
            setSelectedProperties(property.response.propertyArray);
          } else {
            toast.error(property.resStr);
          }
        });
    }
   
  }, [token, props.isEdit, props.accountType, vendor, page, i18n]);

 

  return (
    <div className='featured-area'>
      {props.isEdit ? <>
        <div className="row px-3">
          <div className="col-12">
            <TextField
              label={t("Tagline")}
              sx={{ width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ maxLength: 500 }}
              value={Data2.title}
              size='small'
              multiline
              rows={3}
              onChange={(v) => {
                setData2({
                  ...Data2, title: resolvePersianAndArabicNumbers(
                    v.currentTarget.value?.length >= 500 ? v.currentTarget.value?.substring(0, 500) : v.currentTarget.value
                  )
                });
              }}
            />
            <h6 className='rightalign mt-1 mb-3'>
              {Data2.title
                ? parseInt(Data2.title.length) + "/500"
                : "0/500"}
            </h6>
          </div>
          <div className="col-12">
            <div className='custom-card with-switcher'>
              <div className="card-header">
                <div>
                  <h6>{t("FeaturedRealEstateADs")}</h6>
                </div>
                <div>
                  <button className="outlined-btn px-3" onClick={() => setOpenModal(true)}>{t("AddADs")}</button>
                </div>
              </div>
      
              <div className="card-body">
                <div className='row justify-content-start'>
                  {loadingData ? <LoadingData /> :
                    selectedProperties && selectedProperties.length > 0 ? selectedProperties.map((item, i) => (
                      <div
                        key={i}
                        className='col-xl-3 col-lg-4 col-md-6 col-sm-6 featured-property'
                        style={{ cursor: "pointer" }}>
                        <PropertyBox isAdmin={true} propertey={item} key={i} />
                      </div>
                    )) :
                      <NoData />
                  }
                </div>
                
              </div>
            </div>
          </div>
          <div className='col-12'>
            <LoadingButton
              classes=" primary-btn m-auto"
              handleClick={async (e) => {
                if (DEMO_VENDORS.includes(vendor)) {
                  localStorage.setItem(`properties-${vendor}`, JSON.stringify(selectionModel));
                } else {
                  await saveHomeFeatured({ properties: selectionModel, userId: token });
                }
                props.saveDetails({ data: Data2 });
              }}
              label={t("SaveChanges")}
              loading={props.loading}
            />
              
          </div>
          <Dialog
            fullWidth={true}
            maxWidth={"xl"}
            open={openModal}
            className="custom-dialog model"
            onClose={() => setOpenModal(false)}>
            <DialogTitle>
              <div className="dialog-head">
                <div>
                  <h2>{data.length > 0 ? t("EditFeaturedADs") : t("AddADs")}</h2>
                  <p>{t("YouCanSelectADsByClickingOnThem")}</p>
                </div>
                <div className="dialog-actions">
                  <button className="px-3 primary-btn" onClick={() => {
                    setOpenModal(false);
                    let temp = [];
                    for (let id of selectionModel) {
                      let item = data.filter(i => i.propertyId === id)[0];
                      temp.push(item);
                    }
                    setSelectedProperties(temp);
                  }
                  }>
                    {t("Save")}
                  </button>
                  <button className="px-3 outlined-btn" onClick={() => setOpenModal(false)}>
                    {t("Cancel")}
                  </button>
                </div>
              </div>
          
            </DialogTitle>
            <DialogContent>
              <div className='row justify-content-start'>
              
                <div style={{ display: 'flex', height: '400px', width: '100%', flexDirection: 'column' }}>

                  <TableContainer className="table-container responsive-table"
                    component={Paper}>
                    <Table sx={{ minWidth: '100%' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="right">{t("CoverImage")}</TableCell>
                          <TableCell align="right">{t("PropertyType")}</TableCell>
                          <TableCell align="right">{t("ADPurpose")}</TableCell>
                          <TableCell align="right">{t("Price")}</TableCell>
                          <TableCell align="right">{t("Actions")}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {loadingData ? <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        ><TableCell colSpan={5}><LoadingData /></TableCell></TableRow> :
                          data?.length > 0 ? data.map((row) => (
                            <TableRow
                              key={row.propertyId}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 }, '& td, & th': { pt: 0.57, pb: 0.57 } }}
                            >
                              <TableCell align="right" component="th" scope="row">
                                <Avatar sx={{ width: 48, height: 48, }}
                                  variant="square"
                                  src={row.photos.split(',')[0] ? row.photos.split(',')[0] :
                                    `${publicUrl}assets/img/defimgs/${row.propertyType}.svg`} />
                              </TableCell>
                              <TableCell align="right">{row.propertyTypeName}</TableCell>
                              <TableCell align="right">{row.listedFor === '2' ? t("ForSale") : t("ForRent")}</TableCell>
                              <TableCell align="right">{Intl.NumberFormat("en").format(row.defaultPrice)}</TableCell>
                              <TableCell align="right">
                                <div className="actions-wrapper">
                                  <button
                                    onClick={() => {
                                      if (selectionModel.length <= 3) {
                                        setSelectionModel((old) => old.filter(i => i === row.propertyId).length > 0 ? old.filter(i => i !== row.propertyId) : [...old, row.propertyId]);
                                      } else {
                                        toast.error(t("YouCannotSelectMoreThan3ADs"));
                                      }
                                    }}
                                    className="custom-btn btn-blue">
                                    {selectionModel.filter(i => i === row.propertyId).length === 0 ? t("Select") : t("DeSelect")}
                                  </button>
                                                         
                                </div>
                              </TableCell>

                            </TableRow>
                          )) :
                            <TableRow
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            ><TableCell colSpan={5}><NoData msg={t("ThereAreNoADsPublishedByYou")} /></TableCell></TableRow>
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {totalCount > pageSize && (
                    <Pagination
                      sx={{ '& .MuiPagination-ul': { flexDirection: 'row', marginTop: '25px' } }}
                      count={parseInt(Math.ceil(totalCount / pageSize))}
                      page={page}
                      onChange={(event, value) => {
                        setPage(+value);
                      }}
                      color="primary" />
                  )}
                  

                </div>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </>
        :
        selectedProperties.length > 0 ? (
          <>
            <div className='col-12 justify-content-center align-items-center flex-column d-flex' style={{ marginBottom: '20px', marginTop: '40px' }}>
              <span className="section-prefix">{t("OurAds")}</span>
              <h2 className='sec-title'>{t("CollectionOfOurFeaturedADs")}</h2>
              <p className="sec-desc">{Data2.title}</p>
            </div>
            <div className='row justify-content-end featured-props'>
              {selectedProperties.map((item, i) => (
                <div
                  key={i}
                  data-aos='flip-left'
                  className='col-xl-3 col-lg-4 col-md-6 col-sm-6'
                  style={{ cursor: "pointer" }}>
                  <PropertyBox propertey={item} key={i} />
                </div>
              ))}
            </div>
            <div className="col-12 mt-5 mb-4">
              <Link to='/properties' className="py-2 outlined-btn mx-auto d-flex" style={{ width: 'max-content' }}>{t("ViewAllADs")}</Link>
            </div>
          </>
        ) : <></>
      }
    </div>
  );
}

export default FeaturedPropertiesSection;
