import React, { useEffect, useState } from "react";
import PageHeader from "./global-components/page-header";
import { getProjectDetails, saveInterest } from "../api/userApi";
import GoogleMapsContainer from "../components/googleMapComponent";
import Models from "../components/section-components/models";
import { Link, useParams } from "react-router-dom";
import { DownArrow } from "../constants/icons";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination,EffectFade, Keyboard  } from 'swiper';
import SwiperCore, { Autoplay } from "swiper";
import { ChevronLeft, ChevronRight, Clear } from "@mui/icons-material";
import AppLayout from "./layouts/app-layout";
import LoadingButton from "./global-components/loading-btn";
import { Alert, Box, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import { preventString, resolvePersianAndArabicNumbers, validatePhone, validateURL } from "../helpers";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useAuth } from "../helpers/context";
import { DARK_TEMPLATES, MODERN_TEMPLATES } from "../constants";
import { useTranslation } from "react-i18next";

SwiperCore.use([Autoplay]);
const publicUrl = process.env.REACT_APP_URL + "/";
const isAdmin = window.location.href.includes("admin");

const ProjectDetails = (props) => {
  let { id } = useParams();
  const [Data, setData] = useState({ id: "" });
  const [projectId] = useState(id || "1");
  const [loadingButton, setLoadingButton] = useState(false);
  const [newInterest, setNewInterest] = useState({
    name: "",
    projectId: "",
    phone: '',
    unitId: ''
  });
  const [errors, setErrors] = useState({});
  const { vendor, templateId } = useAuth();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (vendor)
      getProjectDetails({
        userName: vendor,
        projectId: projectId,
        language: i18n.language === 'ar' ? '0' : '1',
      }).then((_response) => {
        if (_response.resCode === 0) setData(_response.response);
      });
  }, [projectId, vendor, i18n]);

  const formIsValid = () => {
    const _errors = {};
    if (!newInterest.name) _errors.name = t("EnterName");
    if (!newInterest.phone) _errors.phone = t("EnterPhoneNumber");
    else if (!validatePhone(newInterest.phone)) _errors.phone = t("PhoneNumberIsIncorrect");
    //validate unit id 

    setErrors(_errors);
    // Form is valid if the errors object has no properties
    return Object.keys(_errors).length === 0;
  };
  
  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      formIsValid();
    }
  }, // eslint-disable-next-line
    [i18n.language]);

  const onSubmit = () => {
    if (!formIsValid()) return;
    setLoadingButton(true);
    saveInterest({
      vendorName: vendor,
      projectId: projectId,
      unit: newInterest.unitId,
      phone: newInterest.phone,
      name: newInterest.name,
      language: i18n.language === 'ar' ? '0' : '1'
    }).then((res) => {
      if (res.resCode === 0) {
        Swal.fire({
          title: t("DoneSuccessfully"),
          text: t("SentSuccessfullyWeWillContactYouAsSoonAsPossible"),
          icon: 'success',
          confirmButtonText: t("Close")
        });
        setNewInterest({
          ...newInterest, name: "",
          phone: '',
          unitId: ''
        });
      }
      else
        Swal.fire({
          title: t("AnErrorOccurred"),
          text: res.resStr,
          icon: 'error',
          confirmButtonText: t("OK2")
        });
      setLoadingButton(false);
      
    }).catch(e => setLoadingButton(false));
  };

  return (
    <AppLayout withFooter={!isAdmin} withoutNav={isAdmin}>
      {Data.id && (
        <div style={{ width: '100%', maxWidth: '135rem', margin: 'auto' }}>
          {isAdmin ? <Link className="primary-btn py-2 px-3 back-btn" to="/admin/projects">{t("BackToProjectsPage")}</Link> : <></>}
          <div className="position-relative">
            {Data.brochure && [...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) && !isAdmin && (
              <a
                className="btn white-btn position-absolute brochure-btn"
                href={validateURL(Data.brochure) ? Data.brochure.substring(0, Data.brochure.indexOf('?')) : ''}
                target='_blank'
                rel='noreferrer'>
                {t("DownloadTheBrochure")}
                <DownArrow />
              </a>
            )}
            <PageHeader
              isAdmin={isAdmin}
              name={[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) && !isAdmin ? Data.name : ''}
              imgCover={Data.images.length > 0}
              imageUrl={Data.images.length > 0 ? Data.images[0] : publicUrl + "assets/img/defimgs/1.svg"}
              images={Data.images.length > 0 ? Data.images : [publicUrl + "assets/img/defimgs/1.svg"]}
              slider={[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) && !isAdmin}
            />
          </div>
          
          <div className={`project-details-page ${isAdmin ? 'admin' : ''}`}>
            <div className={isAdmin ? '' : 'project-intro'}>
              {[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) && !isAdmin && <img src="/assets/img/shape-4.png" alt="sahpe" className="shape" />}

              {Data.brochure && (![...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) || isAdmin) && (
                <a
                  className="btn white-btn"
                  href={validateURL(Data.brochure) ? Data.brochure.substring(0, Data.brochure.indexOf('?')) : ''}
                  target='_blank'
                  rel='noreferrer'>
                  {t("DownloadTheBrochure")}
                  <DownArrow />
                </a>
              )}
              <div className="project-section">
                <div className={[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) && !isAdmin ? '' : 'container-v2'}>
                  {Data.logo && <img src={Data.logo} alt='logo' className="logo" />}
                  <div className="flex-wrapper">
                    {[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) && !isAdmin ? null : <h1 className="project-name">{Data.name}</h1>}
                    <p className='project-desc text-center'>{Data.description}</p>
                    {/* <h4 className='project-status'>{Data.status}</h4> */}
                    <div className='project-features-wrapper'>
                      <h4 className="text-center">{t("ProjectSpecifications")}</h4>
                      <div className='project-features'>
                        {Data.status && <div className="project-feature">
                          <span className="text-center">{t("ProjectStatus")}</span>
                          <span className="text-center">{Data.status}</span>
                        </div>}
                   
                        <div className="project-feature">
                          <span className="text-center">{t("ProjectArea")}</span>
                          <span className="text-center">{Data.area ? Intl.NumberFormat("en").format(Data.area) : 0}</span>
                        </div>
                        <div className="project-feature">
                          <span className="text-center">{t("TotalUnits")}</span>
                          <span className="text-center">{Data.totalUnits}</span>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            {(![...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) || isAdmin) && Data.images && Data.images.length > 0 && (
              <div className="project-section p-0 project-gallery">
                <>
                  <Swiper
                    key={i18n.language +'ii'}
                    modules={[Navigation, Pagination, EffectFade, Keyboard]}
                    keyboard={{ enabled: true }}
                    // mousewheel
                    spaceBetween={0}
                    slidesPerView={1}
                    navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
                    pagination={{ clickable: true, el: '.swiper-pagination' }}
                    // autoplay={{
                    //   delay: 2200,
                    //   pauseOnMouseEnter: false,
                    //   disableOnInteraction: false,
                    // }}
                    // loop={true}
                    effect="fade"
                    speed={2600}
                  >
                    {Data.images.map((item, i) => (
                      <SwiperSlide key={i}>  <img src={item} alt='img' /></SwiperSlide>
                    ))}
                  </Swiper>
                  {Data.images.length > 1 &&
                    <>
                      <div className="swiper-pagination"></div>
                      <div className="swiper-button-next"><ChevronRight /></div>
                      <div className="swiper-button-prev"><ChevronLeft /></div>
                    </>
                  }
                </>
              </div>
            )}
            {Data.sections && Data.sections.length > 0 && (
              <div className="project-section">
                <div className='container-v2'>
                  {Data.sections.map((item, i) => [...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) && !isAdmin ? (
                    <div className={`row template-3__about-wrapper my-5 mx-0 ${i % 2 === 0 ? 'rtl' : ''}`} key={item.id}>
                      <div className="col-12 col-lg-6 template-3__about-content p-0">
                        <div className={`process-area`}>
                          <div className='row m-0'>
                            {[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) && !isAdmin && <img src="/assets/img/shape-4.png" alt="sahpe" className="shape" />}
                            <div className='col-12 p-0'>
                              <div className={`col-12 justify-content-center flex-column d-flex`}>
                                <h2 className='sec-title'>
                                  {item.title}
                                </h2>
                                <p className="sec-desc">{item.description}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-5 p-0">
                        <div className="video-bg">
                          <img src={item.image} alt={item.title} />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div key={item.id} className='row section section-1 p-0' style={{ direction: i % 2 === 0 ? 'rtl' : 'ltr' }}>
                      <div className='col-12 col-lg-6 image-part'
                        style={{
                          background: `url(${item.image})`,
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat'
                        }}>
                        {/* <img src={publicUrl+'assets/img/flower.svg'} alt="flower" className="flower flower-1"/> */}
                        {/* <img src={publicUrl+'assets/img/flower.svg'} alt="flower" className="flower flower-2"/> */}
                      </div>
                      <div className='col-12 col-lg-5'>
                        <h4>{item.title}</h4>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
       
            {Data.units.length > 0 && (
              <div className="project-section models-section">
                <div className='container-v2 text-center'>
                  <Models data={Data.units} />
                </div>
              </div>
            )}
            {Data.latitude && (
              <div className="project-section project-location">
                <div className='container-v2 text-center'>
                  <h3 className="mb-3 text-center">{t("ProjectGeographicAddress")}</h3>
                  <p className='text-center'>{Data.locationDesc}</p>
                  <GoogleMapsContainer
                    lat={Data.latitude}
                    lng={Data.longitude}
                    zoom={15}
                  />
                </div>
              </div>
            )}
            <div className="project-section p-0">
              <div className='callus-area' style={[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) && !isAdmin ? { background: 'none' } : {}}>
                <div className='row'>
                  <div className='col-12 justify-content-center align-items-center flex-column d-flex callus'>
                    <span className="section-prefix">{t("InterestedInProject")}</span>
                    <h2 className='sec-title' style={{ marginBottom: '20px' }}>{ t("SendYourInterestNow")}</h2>
                    <div className="container-v2">
                      <div className={`row mx-auto mt-5 ${[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) && !isAdmin ? 'v2' : 'col-xl-3'} contact-form-wrap flex-column col-12 col-sm-11 col-md-6 col-lg-4 `}>
                        <div className='col-12 mb-3'>
                          <FormControl
                            error={Boolean(errors.name)}
                            className="col-12"
                          >
                            <TextField
                              label={t("Name")}
                              sx={{ width: "100%" }}
                              type='text'
                              InputLabelProps={{
                                shrink: true,
                              }}
                              autoComplete="off"
                              required
                              variant={'outlined'}
                              size='small'
                              onChange={(e) => {
                                setNewInterest({ ...newInterest, name: resolvePersianAndArabicNumbers(e.currentTarget.value) });
                              }}
                              error={Boolean(errors.name)}
                              value={newInterest.name}
                            />
                            {errors.name &&
                              <FormHelperText component={Box}>
                                <Alert severity="error" className="custom-alert">
                                  {errors.name}
                                </Alert>
                              </FormHelperText>
                            }
                          </FormControl>
                        </div>

                        <div className='col-12 mb-3'>
                          <FormControl
                            error={Boolean(errors.phone)}
                            className="col-12"
                          >
                            <TextField
                              label={t("PhoneNumber")}
                              sx={{ width: "100%" }}
                              type='text'
                              InputLabelProps={{
                                shrink: true,
                              }}
                              autoComplete="off"
                              required
                              variant={'outlined'}
                              size='small'
                              onChange={(e) => {
                                setNewInterest({ ...newInterest, phone: preventString(e.currentTarget.value) });
                              }}
                              error={Boolean(errors.phone)}
                              value={newInterest.phone}
                            />
                            {errors.phone &&
                              <FormHelperText component={Box}>
                                <Alert severity="error" className="custom-alert">
                                  {errors.phone}
                                </Alert>
                              </FormHelperText>
                            }
                          </FormControl>
                        </div>
                        {Data.units?.length > 0 && (
                          <div className='col-12 mb-3'>
                            <FormControl variant="outlined" className="w-100">
                              <InputLabel shrink htmlFor='unitid1'>{t("ResidentialUnitModel")}</InputLabel>
                              <Select
                                sx={{ width: "100%" }}
                                size="small"
                                onChange={(event) => {
                                  setNewInterest({
                                    ...newInterest,
                                    unitId: event.target.value,
                                  });
                                }}
                                label={t("ResidentialUnitModel")}
                                inputProps={{
                                  name: "unitid1",
                                  id: "unitid1",
                                }}
                                value={newInterest.unitId}
                                endAdornment={
                                  <IconButton sx={{ display: newInterest.unitId ? "" : "none" }}
                                    onClick={() => setNewInterest({
                                      ...newInterest,
                                      unitId: '',
                                    })}>
                                    <Clear sx={{ fontSize: 15 }} />
                                  </IconButton>}
                                input={<OutlinedInput
                                  notched
                                  value={newInterest.unitId}
                                  label={t("ResidentialUnitModel")} />}
                                labelId="unitid1"
                              >
                                {Data.units.map((item) => <MenuItem value={item.name} key={item.id}>{item.name}</MenuItem>)}
                              </Select>
                            </FormControl>
                          </div>
                        )}
                        <div className='btn-wrap text-center'>
                          <LoadingButton
                            type="submit"
                            style={{ width: 'fit-content' }}
                            classes='btn primary-btn w-100 d-flex align-items-center justify-content-center mx-auto mb-3'
                            label={t("Send")}
                            loading={loadingButton}
                            handleClick={onSubmit}
                          />
                        </div>
                      </div>
                    </div>
                    {[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) && !isAdmin ? null : <>
                      <span className="circle circle-1"></span>
                      <span className="circle circle-2"></span>
                      <span className="circle circle-3"></span>
                      <span className="circle circle-4"></span>
                    </>}
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </AppLayout>
  );
}

export default ProjectDetails;
