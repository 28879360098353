import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { uploadWlFileBase64 } from "../api/userApi";
import { ArrowBack, Check, UploadFile } from "@mui/icons-material";
import LoadingButton from "./global-components/loading-btn";
import DragAndDropFileUploader from "./global-components/drag-drop-file-uploader";
import { useAuth } from "../helpers/context";
import { DEMO_VENDORS } from "../constants";
import { useTranslation } from "react-i18next";

const publicUrls = "../assets/img/logo/";

const UploadLogo = (props) => {
  const { vendor } = useAuth();
  const [publicUrl, setPublicUrl] = useState("https://amakknbackendupload.s3.me-south-1.amazonaws.com/whitelabel/" + vendor + "/");
  const { t, i18n } = useTranslation();
  const [ItemSelected, setItemSelected] = React.useState(undefined);
  const [imageUploaded, setImageUploaded] = React.useState(false);
  const [loadingUploadLogo, setLoadingUploadLogo] = React.useState(false);
  const [logoTOView, setLogoTOView] = React.useState(props.LogoTOView);

  useEffect(() => {
    if (vendor) {
      setPublicUrl("https://amakknbackendupload.s3.me-south-1.amazonaws.com/whitelabel/" + vendor + "/");
    }
  }, [vendor]);
  // const [LogoTOView, setLogoTOView] = React.useState(
  //   publicUrl + "assets/img/Logo_black.png"
  // );
  let images = [
    publicUrls + "3.svg",
    publicUrls + "4.svg",
    publicUrls + "5.svg",
    publicUrls + "2.svg",
    publicUrls + "1.svg",
  ];

  function uploadImage(file, type, path) {
    let reader = new FileReader();
    // Convert the file to base64 text
    reader.readAsDataURL(file);
    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      let fileInfo = {
        pathWithFileName: path,
        base64Data: reader.result,
        typeOfFile: type,
        userId: props.token,
        language: i18n.language === 'ar' ? '0' : '1',
      };
      uploadWlFile(fileInfo, 1, true);
    };
  }

  function uploadWlFile(fileInfo, from, loading) {
    if (DEMO_VENDORS.includes(vendor)) {
      setLogoTOView(fileInfo.base64Data);
      props.setLogoTOView(fileInfo.base64Data);
      localStorage.setItem(`logo-${vendor}`, fileInfo.base64Data);
      toast.success(t("LogoUploadedSuccessfully"));
    } else {
      setLoadingUploadLogo(loading);
      uploadWlFileBase64(fileInfo).then((resp) => {
        if (resp.resCode === 0) {
          props.setLogoTOView(
            publicUrl + "assets/img/Logo_black.png?" + Math.random()
          );
          setLogoTOView(publicUrl + "assets/img/Logo_black.png?" + Math.random());
          toast.success(t("LogoUploadedSuccessfully"));
          setImageUploaded(true);
          if (from === 1) setItemSelected("");
        } else {
          toast.error(resp.resStr);
        }
        setLoadingUploadLogo(false);
      });
    }
  }

  async function saveImage(imageURL) {
    let img = new Image();
    img.src = imageURL;
    img.crossOrigin = "Anonymous";

    img.onload = function () {
      let canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d");

      canvas.height = img.naturalHeight;
      canvas.width = img.naturalWidth;
      ctx.drawImage(img, 0, 0);

      // Unfortunately, we cannot keep the original image type, so all images will be converted to PNG
      // For this reason, we cannot get the original Base64 string
      let uri = canvas.toDataURL("image/png"),
        b64 = uri;

      let fileInfo = {
        pathWithFileName: "/assets/img/Logo_black.png",
        base64Data: b64,
        typeOfFile: "png",
        userId: props.token,
        language: i18n.language === 'ar' ? '0' : '1',
      };
      uploadWlFile(fileInfo, 2, false);
      if (props.setOpenLogoModal) {
        props.setOpenLogoModal(false);
      }
    };
  }

  return (
    <div style={{ background: "#fff" }}>
      <div className='row text-center m-0'>
        <div className='col-12 d-flex flex-column align-items-center justify-content-center p-0'>
          <h3 className='modal-heading'>{t("Logo")}</h3>
          <p className='modal-desc'>
            {t("ChooseLogoFromTheFollowingImagesOrUploadLogoOfYourChoice")}
          </p>
        </div>
        <div className='col-12 row m-0 justify-content-between align-items-start mb-3'>
          <div className='col-12 mb-5 mb-lg-0 col-lg-4 preview-logo order-1 order-lg-0 mt-4 mt-lg-0'>
            <h3 style={{ fontWeight: 600, fontSize: 20, textAlign: "right" }}>
              {" "}
              {t("LogoPreview")}
            </h3>
            <div>
              {logoTOView || ItemSelected ? (
                <img
                  src={logoTOView || ItemSelected}
                  alt='logo'
                  className='preview-logo-img'
                />
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className='col-12 col-lg-8'>
            <div className='container parent p-0 m-0 p-lg-auto m-lg-auto'>
              <h3 style={{ fontWeight: 600, fontSize: 20 }}>
                {t("PreSetLogos")}
              </h3>
              <div className='image-selectors-wrapper'>
                {images.map((e, i) => (
                  <div
                    key={i}
                    className={`image-selector ${ItemSelected === e ? "selected" : ""}`}>
                    <input
                      type='radio'
                      name='imgbackground'
                      id={e}
                      className='d-none imgbgchk'
                      onChange={() => {
                        setItemSelected(e);
                        saveImage(e);
                      }}
                      defaultValue
                    />
                    <label htmlFor={e}>
                      <img src={e} alt={e} />
                      <div className='tick_container'>
                        <div className='tick'>
                          <Check sx={{ fontWeight: 900 }} />
                        </div>
                      </div>
                    </label>
                  </div>
                ))}
              </div>
              <h3 style={{ fontWeight: 600, fontSize: 20 }}>
                {t("UploadNewLogo")} <span style={{ fontWeight: 600, fontSize: '13px' }}>({t("AllowedFormats")}: PNG, JPG, JPEG)</span>
              </h3>
              <DragAndDropFileUploader
                singleFile
                ImageOnly
                icon={<UploadFile />}
                loading={loadingUploadLogo}
                title={t("UploadLogo")}
                id='contained-button-file2'
                classes='custom-btn-outlined px-4'
                style={{
                  direction: "ltr",
                  marginTop: "16px",
                  boxShadow: "none",
                }}
                handleChange={(e) =>
                  uploadImage(
                    e.target.files[0],
                    "png",
                    "/assets/img/Logo_black.png"
                  )
                }
                buttonMode
                darkIcon
              />
            </div>
          </div>
        </div>
      </div>

      {props.goNext && (
        <div className='col-12 mt-5 text-center next-back-btns'>
          {/* <button className="btn outlined-btn " onClick={props.goBack}>
            <ArrowBack style={{transform:'rotate(180deg)'}} /> السابق
        </button> */}
          <LoadingButton
            classes='btn primary-btn'
            handleClick={() => {
              if (imageUploaded || props.LogoTOView) props.goNext();
              else toast.error(t("PleaseChooseOrUploadLogo"));
            }}
            label={
              props.loadingButton ? t("Next") : (
                <>
                  {t("Next")} <ArrowBack />
                </>
              )
            }
            loading={props.loadingButton}
          />
        </div>
      )}
    </div>
  );
}

export default UploadLogo;
