import React, { useState } from "react";
import { useRef } from "react";
import Preview from "./components/preview";
import SubHeader from "../../../components/global-components/sub-header";
import ColorPalette from "./components/color-palette";
import { customTheme, useAuth } from "../../../helpers/context";
import { DARK_TEMPLATES, DEMO_VENDORS } from "../../../constants";
import { uploadWlFileBase64 } from "../../../api/userApi";
import { toast } from "react-toastify";
import { Button, Hidden } from "@mui/material";
import LoadingButton from "../../../components/global-components/loading-btn";
import { useTranslation } from "react-i18next";

const StylesPage = () => {
  const { token, vendor, setTheme, templateId } = useAuth();
  const childRef = useRef(null);
  const [CSS, setCSS] = useState({
    mainColorOne: "",
    mainColorTwo: "",
    headingColor: "",
    paragraphColor: "",
    btnColor: "",
    bodyFontSize: "",
    bodyFont: "Dubai",
  });
  const { t, i18n } = useTranslation();
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingReset, setLoadingReset] = useState(false);

  var CSSString = {
    css: [
      ":root {",
      `--main-color-one:${DARK_TEMPLATES.includes(+templateId) ? '#39DB98;' : '#243ead;'}`,
      `--main-color-two:${DARK_TEMPLATES.includes(+templateId) ? '#282828;' : '#fff;'}`,
      "--font-color:#333333;",
      `--heading-color:${DARK_TEMPLATES.includes(+templateId) ? '#969696;' : '#333333;'}`,
      `--paragraph-color:${DARK_TEMPLATES.includes(+templateId) ? '#efefef;' : '#666666;'}`,
      "--btn-color:#fff;",
      '--body-font:"Dubai";',
      "--body-font-size:15px;}"
    ].join(""),
  };
 

  const resetDefault = () => {
    setLoadingReset(true);
    if (DEMO_VENDORS.includes(vendor)) {
      localStorage.setItem(`palette-${vendor}`, JSON.stringify({
        mainColorOne: DARK_TEMPLATES.includes(+templateId) ? '#39DB98' : '#243ead',
        mainColorTwo: DARK_TEMPLATES.includes(+templateId) ? '#282828' : '#fff',
        headingColor: DARK_TEMPLATES.includes(+templateId) ? '#969696' : '#333333',
        paragraphColor: DARK_TEMPLATES.includes(+templateId) ? '#efefef' : '#666666',
        btnColor: '#fff',
        bodyFont: "Dubai",
        bodyFontSize: '15px'
      }));
     
      setTheme(customTheme(DARK_TEMPLATES.includes(+templateId) ? '#39DB98' : '#243ead', "Dubai"));
       
      var r = document.querySelector(':root');
      r.style.setProperty('--main-color-one', DARK_TEMPLATES.includes(+templateId) ? '#39DB98' : '#243ead');
      r.style.setProperty('--main-color-two', DARK_TEMPLATES.includes(+templateId) ? '#282828' : '#fff');
      r.style.setProperty('--heading-color', DARK_TEMPLATES.includes(+templateId) ? '#969696' : '#333333');
      r.style.setProperty('--paragraph-color', DARK_TEMPLATES.includes(+templateId) ? '#efefef' : '#666666');
      r.style.setProperty('--btn-color', '#fff');
      r.style.setProperty('--body-font', "Dubai");
      r.style.setProperty('--body-font-size', '15px');
      setCSS({
        mainColorOne: "",
        mainColorTwo: "",
        headingColor: "",
        paragraphColor: "",
        btnColor: "",
        bodyFontSize: "",
        bodyFont: "",
      });
      childRef.current.onReset();
      toast.success(t("ResetSuccessfully"));
      setLoadingReset(false);

    } else {
      let CSSFile = new File([CSSString.css], "colors.css", {
        type: "text/css",
      });
      let reader = new FileReader();
      reader.readAsDataURL(CSSFile);
      reader.onload = () => {
        let bs64 = reader.result;
        uploadWlFileBase64({
          base64Data: bs64,
          pathWithFileName: "/assets/css/colors.css",
          typeOfFile: "css",
          userId: token,
          language: i18n.language === 'ar' ? '0' : '1',
        }).then((resp) => {
          if (resp.resCode === 0) {
            toast.success(t("ResetSuccessfully"));
            const link = resp.response.url;
            const mainStyle = document.getElementById("main-stylesheet");
            if (mainStyle)
              mainStyle.href = link;
              
            setTheme(customTheme(DARK_TEMPLATES.includes(+templateId) ? '#39DB98' : '#243ead', "Dubai"));
            setCSS({
              mainColorOne: "",
              mainColorTwo: "",
              headingColor: "",
              paragraphColor: "",
              btnColor: "",
              bodyFontSize: "",
              bodyFont: "",
            });
            childRef.current.onReset();
          } else {
            toast.error(resp.resStr);
          }
          setLoadingReset(false);
        }).catch(e => setLoadingReset(false));
      };
    }
      
  };

  return (
    <>
      <SubHeader loading={loadingButton}
        CancelElement={
          <Button onClick={resetDefault}
          disabled={loadingReset}
            className="outlined-btn px-3">{t("Reset")}</Button>
        }
        onOK={() => childRef.current.onSubmit()} />
      <div className="cards-wrapper colors">
        <div className='custom-card'>
          <div className="card-header">
            <h4>{t("ColorsAndFonts")}</h4>
            <h6>{t("YouCanChangeTheColorsAndFontsToSuitYourVision")}</h6>
          </div>
          <div className="card-body">
            <ColorPalette setLoading={setLoadingButton} CSS={CSS} setCSS={setCSS} token={token} ref={childRef} />
          </div>
        </div>
        <div className='custom-card' style={{ background: 'transparent', border: 'none' }}>
          <h4 className="m-auto">{t("Preview")}</h4>
          <div className="card-body no-padding">
            <Preview colors={CSS} />
          </div>
        </div>
      </div>
      <Hidden mdDown>
      <div className="py-5 d-flex align-items-center justify-content-center" style={{gap:'10px'}}>
        <LoadingButton
          classes='primary-btn px-5'
          handleClick={() => { childRef.current.onSubmit(); }}
          label={t("SaveChanges")}
          loading={loadingButton}
        />
          <Button onClick={resetDefault}
          disabled={loadingReset}
            className="outlined-btn px-5">{t("Reset")}</Button>
        </div>
        </Hidden>
    </>
  );
};

export default StylesPage;
