import React, { useEffect } from "react";
import { toast } from "react-toastify";
import FormControl from "@mui/material/FormControl";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import { chooseTemplate, getWLFooter, saveWLFooter, uploadWlFileBase64 } from "../api/userApi";
import { ArrowBack, Check } from "@mui/icons-material";
import { DialogContent, DialogTitle } from "@mui/material";
import LoadingButton from "./global-components/loading-btn";
import { customTheme, useAuth } from "../helpers/context";
import { CLASSIC_TEMPLATES, DARK_TEMPLATES, DEFAULT_TEMPLATES, MODERN_TEMPLATES } from "../constants";
import { useTranslation } from "react-i18next";

const publicUrl = process.env.REACT_APP_URL + "/";

const TemplateChooser = forwardRef((props, ref) => {
    const { token, AccountType, templateId, setTemplateId, vendor, setTheme } = useAuth();
    const [open, setOpen] = React.useState(false);
    const { ItemSelected, setItemSelected } = props;
    const [Token] = React.useState(props.token || token);
    const [ImageToView, setImageToView] = React.useState("");
    const [templateName, setTemplateName] = React.useState("");
    const [footerData, setFooterData] = React.useState();
    const { t, i18n } = useTranslation();

    useImperativeHandle(ref, () => ({
        onSubmit() {
            onSubmit();
        }
    }));
    
    const chageTheme = (theme) => {
        saveWLFooter({
            userId: Token,
            language: i18n.language === 'ar' ? '0' : '1',
            description: footerData.description || ' ',
            backgroundColor: theme === 'dark' ? '#222121' : '#D9D9D9',
            showMap: footerData.showMap,
            latitude: footerData.latitude,
            longitude: footerData.longitude,
            address: footerData.address
        });
        let CSSString = {
            css: [
                ":root {",
                `--main-color-one:${DARK_TEMPLATES.includes(+ItemSelected) ? '#39DB98;' : '#4A90E2;'}`,
                `--main-color-two:${DARK_TEMPLATES.includes(+ItemSelected) ? '#282828;' : '#fff;'}`,
                "--font-color:#333333;",
                `--heading-color:${DARK_TEMPLATES.includes(+ItemSelected) ? '#969696;' : '#333333;'}`,
                `--paragraph-color:${DARK_TEMPLATES.includes(+ItemSelected) ? '#efefef;' : '#666666;'}`,
                `--btn-color:#fff;`,
                '--body-font:"Dubai";',
                "--body-font-size:15px;}"
            ].join(""),
        };
        let CSSFile = new File([CSSString.css], "colors.css", {
            type: "text/css",
        });
        let reader = new FileReader();
        reader.readAsDataURL(CSSFile);
        reader.onload = () => {
            let bs64 = reader.result;
            uploadWlFileBase64({
                base64Data: bs64,
                pathWithFileName: "/assets/css/colors.css",
                typeOfFile: "css",
                userId: token,
                language: i18n.language === 'ar' ? '0' : '1',
            }).then((resp) => {
                if (resp.resCode === 0) {
                    const link = resp.response.url;
                    const mainStyle = document.getElementById("main-stylesheet");
                    if (mainStyle)
                        mainStyle.href = link;
                    setTheme(customTheme(DARK_TEMPLATES.includes(+ItemSelected) ? '#39DB98' : '#4A90E2', "Dubai"));
                }
            });
        };
    }
    const onSubmit = () => {
        props.setLoading(true);
        chooseTemplate({
            userId: Token,
            language: i18n.language === 'ar' ? '0' : '1',
            templateId: ItemSelected,
        }).then((resp) => {
            if (resp.resCode === 0) {
                const templateStylesheet = document.getElementById("template-stylesheet");
                if (DEFAULT_TEMPLATES.includes(+ItemSelected)) {
                    document.body.classList.add('default-template');
                    templateStylesheet.href = "/assets/css/panel.css"
                    chageTheme();
                } else if (MODERN_TEMPLATES.includes(+ItemSelected)) {
                    document.body.classList.add('modern-template');
                    templateStylesheet.href = "/assets/css/templates-3-4.css"
                    chageTheme();
                } else if (DARK_TEMPLATES.includes(+ItemSelected)) {
                    document.body.classList.add('dark-template');
                    templateStylesheet.href = "/assets/css/templates-5-6.css";
                    chageTheme('dark');
                
                } else if (CLASSIC_TEMPLATES.includes(+ItemSelected)) {
                    document.body.classList.add('classic-template');
                    templateStylesheet.href = "/assets/css/templates-7-8.css";
                    chageTheme();
                
                }
                setTemplateId(+ItemSelected);
                if (props.goNext) {
                    props.goNext();
                } else
                    toast.success(t("TemplateSelectedSuccessfully"));
            } else {
                toast.error(resp.resStr);
            }
            props.setLoading(false);
        }).catch(e => props.setLoading(false));
    }

  
    
    useEffect(() => {
        getWLFooter({
            vendorName: vendor,
            language: i18n.language === 'ar' ? '0' : '1',
        }).then((res) => {
            if (res.resCode === 0) {
                setFooterData(res.response);
            }
        });
    }, [vendor, i18n]);
        
    const handleClickOpen = (img) => {
        setImageToView(img);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
   
    const itmes = [
        {
            id: "img2",
            name: t("DefaultTemplate"),
            value: "1",
            AccountType: ['1'],
            imgSrc: publicUrl + "assets/img/others/template-01.png",
        },
        {
            id: "img3",
            name: t("DefaultTemplate"),
            value: "2",
            AccountType: ['2'],
            imgSrc: publicUrl + "assets/img/others/template-02.png",
        },
        {
            id: "img3",
            name: t("ModernTemplate"),
            value: "3",
            AccountType: ['1'],
            imgSrc: publicUrl + "assets/img/others/template-03.png",
        },
        {
            id: "img4",
            name: t("ModernTemplate"),
            value: "4",
            AccountType: ['2'],
            imgSrc: publicUrl + "assets/img/others/template-04.png",
        },
        {
            id: "img5",
            name: t("DarkTemplate"),
            value: "5",
            AccountType: ['1'],
            imgSrc: publicUrl + "assets/img/others/template-05.png",
        },
        {
            id: "img6",
            name: t("DarkTemplate"),
            value: "6",
            AccountType: ['2'],
            imgSrc: publicUrl + "assets/img/others/template-06.png",
        },
    ];
    
    function onValueChange(value) {
        setItemSelected(value);
        if (+value !== +templateId)
            props?.setEditMode?.(true);
        else {
            props?.setEditMode?.(false);
        }
        // if (props.goNext) props.goNext();
    }

    return (
        <>
            <FormControl fullWidth={true}>
                <div className='templates-wrapper row'>
                    {itmes.map((e, i) => (
                        e.AccountType.includes(AccountType) &&
                        <div className="template-wrapper position-relative" key={i}>
                            <span className={`template-name ${ItemSelected === e.value ? 'selected' : ''}`}>{e.name}</span>
                            <div className={`template p-0 m-0 ${ItemSelected === e.value ? 'selected' : ''}`}>
                                <input
                                    type='radio'
                                    name='imgbackground'
                                    id={e.id}
                                    className='d-none imgbgchk'
                                    onChange={() => onValueChange(e.value)}
                                    checked={ItemSelected === e.value}
                                />
                                <label htmlFor={e.id}>
                                    <img src={e.imgSrc} alt={e.name} />
                                    <div className='tick_container'>
                                        <div className='tick'>
                                            <Check />
                                        </div>
                                    </div>
                                </label>
                                <button className="primary-btn py-1 px-3" onClick={() => { setTemplateName(e.name); handleClickOpen(e.imgSrc); }}>
                                    {t("Preview")}
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
                {props.goNext && (
                    <div className='col-12 mt-5 text-center next-back-btns'>
                        {/* <button className="btn outlined-btn " onClick={props.goBack}>
                            <ArrowBack style={{ transform: 'rotate(180deg)' }} /> السابق
                        </button> */}
                        <LoadingButton
                            classes="btn primary-btn"
                            handleClick={() => {
                                if (ItemSelected !== '0')
                                    onSubmit();
                                else
                                    toast.error(t("PleaseSelectATemplate"));
                            }}
                            label={props.loadingButton ? t("Next") : <>{t("Next")}  <ArrowBack /></>}
                            loading={props.loadingButton}
                        />
                    </div>
                )}
            </FormControl>
            <Dialog
                onClose={handleClose}
                maxWidth={"lg"}
                className="custom-dialog scrollable-img"
                sx={{ '& .MuiPaper-root': { margin: 0, width: 'calc(100% - 20px)' } }}
                open={open}>
               
                <DialogTitle>
                    <div className="dialog-head">
                        <div>
                            <h2>{templateName}</h2>
                        </div>
                        <div className="dialog-actions close-only">
                            <IconButton
                                aria-label='close'
                                onClick={handleClose}
                                sx={{
                                    color: (theme) => theme.palette.grey[500],
                                }}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>
          
                </DialogTitle>
                <DialogContent>
                    <img src={ImageToView} alt='img1' className="w-100" />
                    <div id="scroll-down">
                        <span className="arrow-down"></span>
                        <span id="scroll-title">{t("ScrollDown")}</span>
                    </div>
                </DialogContent>
            </Dialog>
            {/* <div style={styles.buttons}>
          <Button
            style={styles.saveButton}
            variant='contained'
            type='submit'
            color='primary'
            disabled={ItemSelected === "0" ? true : false}
            onClick={(e) => onSubmit()}>
            {props.goNext ? t("SaveAndContinue") : t("Save")}
          </Button>
        </div> */}
        </>
    );
});

export default TemplateChooser;
